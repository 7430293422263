import axios from '../../utils/api';

export default {
    state: {
        currencies: {},
        currencies_flip: {},
        currencies_all: {},
        currencies_icons: {},
        currency_data: {},
        pairs: {},
        languages: {},

        commissions: [],
        commissionsTrade: [],

        // const TYPE_NETWORK_MAIN = 0;
        // const TYPE_NETWORK_ETH = 1;
        // const TYPE_NETWORK_TRX = 2;
        // const TYPE_NETWORK_BSC = 4;

        // const TYPE_NETWORK_POLYGON = 6;
        // const TYPE_NETWORK_SOLANA = 7;
        // const TYPE_NETWORK_XLM_ASSET = 8;
        // const TYPE_NETWORK_AVALANCHE = 9;
        // const TYPE_NETWORK_ARBITRUM = 10;
        // const TYPE_NETWORK_OPTIMISM = 11;

        networkTypes: {
            0: 'Main',
            1: 'ETH',
            2: 'TRX',
            // 3: 'BNB',
            4: 'BSC',

            6: "POLYGON",
            7: "SOLANA",
            8: "XLM_ASSET",
            9: "AVALANCHE",
            10: "ARBITRUM",
            11: "OPTIMISM",
        },
        networkTypesAliases: {
            0: 'Mainnet',
            1: 'ERC-20',
            2: 'TRC-20',
            3: 'BEP-2',
            4: 'BEP-20',

            6: "POLYGON",
            7: "SOLANA",
            8: "XLM_ASSET",
            9: "AVALANCHE",
            10: "ARBITRUM",
            11: "OPTIMISM",
        },
        networkAliases: {
            ETH: 'ERC-20',
            TRX: 'TRC-20',
            BSC: 'BEP-20',
            Main: 'Mainnet',

            POLYGON: "POLYGON",
            SOLANA: "SOLANA",
            XLM_ASSET: "XLM_ASSET",
            AVALANCHE: "AVALANCHE",
            ARBITRUM: "ARBITRUM",
            OPTIMISM: "OPTIMISM",
        },

        tokenTypes: {
            1: 'Crypto'
        },

        systemWalletTypes: {
            0: 'Omnibus',
            1: 'Withdrawal pool',
            2: 'Gas station',
            3: 'Readonly tx listener',
            4: 'Network deposit'
        },
        systemTransactionsStatuses: {
            0: 'Created',
            1: 'Sent',
            2: 'Pending', // ордер на проведении
            '-1': 'Error',

            3: 'Processing',
            4: 'Repeating lock',
            5: 'Errored and repeated',
        },
        AllCountriesList: {
            "AF": "Afghanistan",
            "AX": "Åland Islands",
            "AL": "Albania",
            "DZ": "Algeria",
            "AS": "American Samoa",
            "AD": "Andorra",
            "AO": "Angola",
            "AI": "Anguilla",
            "AQ": "Antarctica",
            "AG": "Antigua and Barbuda",
            "AR": "Argentina",
            "AM": "Armenia",
            "AW": "Aruba",
            "AU": "Australia",
            "AT": "Austria",
            "AZ": "Azerbaijan",
            "BS": "Bahamas",
            "BH": "Bahrain",
            "BD": "Bangladesh",
            "BB": "Barbados",
            "BY": "Belarus",
            "BE": "Belgium",
            "BZ": "Belize",
            "BJ": "Benin",
            "BM": "Bermuda",
            "BT": "Bhutan",
            "BO": "Bolivia, Plurinational State of",
            "BQ": "Bonaire, Sint Eustatius and Saba",
            "BA": "Bosnia and Herzegovina",
            "BW": "Botswana",
            "BV": "Bouvet Island",
            "BR": "Brazil",
            "IO": "British Indian Ocean Territory",
            "BN": "Brunei Darussalam",
            "BG": "Bulgaria",
            "BF": "Burkina Faso",
            "BI": "Burundi",
            "KH": "Cambodia",
            "CM": "Cameroon",
            "CA": "Canada",
            "CV": "Cape Verde",
            "KY": "Cayman Islands",
            "CF": "Central African Republic",
            "TD": "Chad",
            "CL": "Chile",
            "CN": "China",
            "CX": "Christmas Island",
            "CC": "Cocos (Keeling) Islands",
            "CO": "Colombia",
            "KM": "Comoros",
            "CG": "Congo",
            "CD": "Congo, the Democratic Republic of the",
            "CK": "Cook Islands",
            "CR": "Costa Rica",
            "CI": "Côte d'Ivoire",
            "HR": "Croatia",
            "CU": "Cuba",
            "CW": "Curaçao",
            "CY": "Cyprus",
            "CZ": "Czech Republic",
            "DK": "Denmark",
            "DJ": "Djibouti",
            "DM": "Dominica",
            "DO": "Dominican Republic",
            "EC": "Ecuador",
            "EG": "Egypt",
            "SV": "El Salvador",
            "GQ": "Equatorial Guinea",
            "ER": "Eritrea",
            "EE": "Estonia",
            "ET": "Ethiopia",
            "FK": "Falkland Islands (Malvinas)",
            "FO": "Faroe Islands",
            "FJ": "Fiji",
            "FI": "Finland",
            "FR": "France",
            "GF": "French Guiana",
            "PF": "French Polynesia",
            "TF": "French Southern Territories",
            "GA": "Gabon",
            "GM": "Gambia",
            "GE": "Georgia",
            "DE": "Germany",
            "GH": "Ghana",
            "GI": "Gibraltar",
            "GR": "Greece",
            "GL": "Greenland",
            "GD": "Grenada",
            "GP": "Guadeloupe",
            "GU": "Guam",
            "GT": "Guatemala",
            "GG": "Guernsey",
            "GN": "Guinea",
            "GW": "Guinea-Bissau",
            "GY": "Guyana",
            "HT": "Haiti",
            "HM": "Heard Island and McDonald Islands",
            "VA": "Holy See (Vatican City State)",
            "HN": "Honduras",
            "HK": "Hong Kong",
            "HU": "Hungary",
            "IS": "Iceland",
            "IN": "India",
            "ID": "Indonesia",
            "IR": "Iran, Islamic Republic of",
            "IQ": "Iraq",
            "IE": "Ireland",
            "IM": "Isle of Man",
            "IL": "Israel",
            "IT": "Italy",
            "JM": "Jamaica",
            "JP": "Japan",
            "JE": "Jersey",
            "JO": "Jordan",
            "KZ": "Kazakhstan",
            "KE": "Kenya",
            "KI": "Kiribati",
            "KP": "Korea, Democratic People's Republic of",
            "KR": "Korea, Republic of",
            "KW": "Kuwait",
            "KG": "Kyrgyzstan",
            "LA": "Lao People's Democratic Republic",
            "LV": "Latvia",
            "LB": "Lebanon",
            "LS": "Lesotho",
            "LR": "Liberia",
            "LY": "Libya",
            "LI": "Liechtenstein",
            "LT": "Lithuania",
            "LU": "Luxembourg",
            "MO": "Macao",
            "MK": "Macedonia, the Former Yugoslav Republic of",
            "MG": "Madagascar",
            "MW": "Malawi",
            "MY": "Malaysia",
            "MV": "Maldives",
            "ML": "Mali",
            "MT": "Malta",
            "MH": "Marshall Islands",
            "MQ": "Martinique",
            "MR": "Mauritania",
            "MU": "Mauritius",
            "YT": "Mayotte",
            "MX": "Mexico",
            "FM": "Micronesia, Federated States of",
            "MD": "Moldova, Republic of",
            "MC": "Monaco",
            "MN": "Mongolia",
            "ME": "Montenegro",
            "MS": "Montserrat",
            "MA": "Morocco",
            "MZ": "Mozambique",
            "MM": "Myanmar",
            "NA": "Namibia",
            "NR": "Nauru",
            "NP": "Nepal",
            "NL": "Netherlands",
            "NC": "New Caledonia",
            "NZ": "New Zealand",
            "NI": "Nicaragua",
            "NE": "Niger",
            "NG": "Nigeria",
            "NU": "Niue",
            "NF": "Norfolk Island",
            "MP": "Northern Mariana Islands",
            "NO": "Norway",
            "OM": "Oman",
            "PK": "Pakistan",
            "PW": "Palau",
            "PS": "Palestine, State of",
            "PA": "Panama",
            "PG": "Papua New Guinea",
            "PY": "Paraguay",
            "PE": "Peru",
            "PH": "Philippines",
            "PN": "Pitcairn",
            "PL": "Poland",
            "PT": "Portugal",
            "PR": "Puerto Rico",
            "QA": "Qatar",
            "RE": "Réunion",
            "RO": "Romania",
            "RU": "Russian Federation",
            "RW": "Rwanda",
            "BL": "Saint Barthélemy",
            "SH": "Saint Helena, Ascension and Tristan da Cunha",
            "KN": "Saint Kitts and Nevis",
            "LC": "Saint Lucia",
            "MF": "Saint Martin (French part)",
            "PM": "Saint Pierre and Miquelon",
            "VC": "Saint Vincent and the Grenadines",
            "WS": "Samoa",
            "SM": "San Marino",
            "ST": "Sao Tome and Principe",
            "SA": "Saudi Arabia",
            "SN": "Senegal",
            "RS": "Serbia",
            "SC": "Seychelles",
            "SL": "Sierra Leone",
            "SG": "Singapore",
            "SX": "Sint Maarten (Dutch part)",
            "SK": "Slovakia",
            "SI": "Slovenia",
            "SB": "Solomon Islands",
            "SO": "Somalia",
            "ZA": "South Africa",
            "GS": "South Georgia and the South Sandwich Islands",
            "SS": "South Sudan",
            "ES": "Spain",
            "LK": "Sri Lanka",
            "SD": "Sudan",
            "SR": "Suriname",
            "SJ": "Svalbard and Jan Mayen",
            "SZ": "Swaziland",
            "SE": "Sweden",
            "CH": "Switzerland",
            "SY": "Syrian Arab Republic",
            "TW": "Taiwan, Province of China",
            "TJ": "Tajikistan",
            "TZ": "Tanzania, United Republic of",
            "TH": "Thailand",
            "TL": "Timor-Leste",
            "TG": "Togo",
            "TK": "Tokelau",
            "TO": "Tonga",
            "TT": "Trinidad and Tobago",
            "TN": "Tunisia",
            "TR": "Turkey",
            "TM": "Turkmenistan",
            "TC": "Turks and Caicos Islands",
            "TV": "Tuvalu",
            "UG": "Uganda",
            "UA": "Ukraine",
            "AE": "United Arab Emirates",
            "GB": "United Kingdom",
            "US": "United States",
            "UM": "United States Minor Outlying Islands",
            "UY": "Uruguay",
            "UZ": "Uzbekistan",
            "VU": "Vanuatu",
            "VE": "Venezuela, Bolivarian Republic of",
            "VN": "Viet Nam",
            "VG": "Virgin Islands, British",
            "VI": "Virgin Islands, U.S.",
            "WF": "Wallis and Futuna",
            "EH": "Western Sahara",
            "YE": "Yemen",
            "ZM": "Zambia",
            "ZW": "Zimbabwe"
        },
        EUCountriesList: [
            "AT",
            "BE",
            "BG",
            "CY",
            "CZ",
            "DE",
            "DK",
            "EE",
            "ES",
            "FI",
            "FR",
            "GR",
            "HR",
            "HU",
            "IE",
            "IT",
            "LT",
            "LU",
            "LV",
            "MT",
            "NL",
            "PL",
            "PT",
            "RO",
            "SE",
            "SI",
            "SK"
        ],

        settingsUpdates: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        settingsUpdatesTypes: {
            1: 'Risk Level',
            2: 'Account Status',
            3: 'Q-re approve',
            4: 'Verification status',
            5: 'Email 2FA',
            6: 'Phone 2FA',
            7: 'Send q-re to user',
            8: 'Update user email',
            9: 'Update user phone',
            10: 'Update user username',
            11: "Send verification link to user",
            12: "Reset / Restore verif. results",
            13: "Reset / Restore verif. attempts",
            14: "User Status",
            15: "Password",
            16: "Deposit & Withdrawal Fees Modified ",
            17: "Currency Deposit On/Off",
            18: "Currency Withdrawal On/Off ",
            19: "Bot Settings Modified",
            20: "Pair Settings Modified",
            21: "New Currency Created ",
            22: "New Pair Created",
            23: "Pair Trading Started / Stopped ",
            24: "Pair On/Off",
            25: "Currency On/Off",
            26: "Currency settings Modified",
        },

        modalIsFullscreen: false,

        topTickers: [],

        frontend_domain: null
    },
    getters: {
        getCurrenciesAllList: (state) => state.currencies_all,
        getCurrencyFromAllList: (state) => (id) => id in state.currencies_all ? state.currencies_all[id] : null,
        getConfigCurrenciesList: (state) => state.currencies,
        getConfigCurrenciesListByIso: (state) => state.currencies_flip,
        getCurrencyData: (state) => state.currency_data,
        getCurrency: (state) => (id) => id in state.currencies ? state.currencies[id] : null,

        getPairs: (state) => state.pairs,
        getPair: (state) => (id) => id in state.pairs ? state.pairs[id] : null,

        getSystemWalletTypes: (state) => state.systemWalletTypes,

        getSystemTransactionsStatuses: (state) => state.systemTransactionsStatuses,

        /**
         * Currencies && Trade Commissions
         */
        getCurrenciesCommission: (state) => state.commissions,
        getTradeCommission: (state) => state.commissionsTrade,

        getNetworkTypes: (state) => state.networkTypes,
        getNetworkTypesAliases: (state) => state.networkTypesAliases,
        getNetworkAliases: (state) => state.networkAliases,

        getAllCountriesList: state => state.AllCountriesList,
        getEUCountriesList: state => state.EUCountriesList,

        getSettingsUpdatesHistoryList: (state) => state.settingsUpdates,
        getSettingsUpdatesTypes: (state) => state.settingsUpdatesTypes,

        getModalIsFullscreen: (state) => state.modalIsFullscreen,
        getLanguagesList: (state) => state.languages,
        getTopTickersList: (state) => state.topTickers,

        getFrontendDomain: (state) => state.frontend_domain,
    },
    mutations: {
        SET_CURRENCIES_LIST(state, data) {
            state.currencies_all = data;
            state.currencies = data;

            const flippedObject = {};

            for (const key in data) {
                flippedObject[data[key]] = key;
            }

            state.currencies_flip = flippedObject;

            // for (const id in data) {
            //     const currency = data[id];

            //     state.currencies_flip[data[id].iso3] = currency;
            // }

            //            if (process.env.VUE_APP_ACTIVE_CURRENCIES) {
            //                const filter = process.env.VUE_APP_ACTIVE_CURRENCIES.split(',');
            //
            //                state.currencies = Object.fromEntries(Object.entries(data).filter(([, v]) => filter.includes(v.iso3) || Object.entries(v.networks).filter(([i, net]) => {
            //                    if (filter.includes(v.iso3 + '-' + net.name.slice(0, 1))) {
            //                        return true;
            //                    }
            //
            //                    delete v.networks[i];
            //
            //                    return false;
            //                }).length > 0));
            //            }
            //
            //            for (const id in state.currencies) {
            //                const currency = state.currencies[id];
            //                currency.id = id;
            //
            //                state.currencies_flip[data[id].iso3] = currency;
            //            }
        },

        SET_PAIRS_LIST(state, data) {
            state.pairs = data;
        },

        SET_CURRENCY_DATA(state, data) {
            state.currency_data = data;
        },

        SET_CURRENCIES_COMMISSIONS(state, data) {
            const commissions = [];
            const commissionsTrade = [];


            for (let id in data.all_currency) {
                const iso3 = data.all_currency[id];
                const deposit = data.commission_refill[iso3];
                const withdraw = data.commission[iso3];

                // Find commission for trade pair
                const pair_id = data.all_pairs_flip[iso3 + 'USDT']
                const trade = data.trade_commission[pair_id];

                commissions.push({
                    iso3,
                    id,
                    /**
                     * Deposit
                     */
                    fee_deposit_fixed: deposit?.fixed,
                    fee_deposit_min: deposit?.min_refill,
                    fee_deposit_percent: deposit?.percent,
                    deposit_is_on: !!deposit,

                    /**
                     * Withdraw
                     */
                    fee_withdraw_fixed: withdraw?.fixed,
                    fee_withdraw_percent: withdraw?.percent,
                    fee_withdraw_min: withdraw?.min_withdraw,
                    withdraw_is_on: !!withdraw,

                    /**
                     * Trading
                     */
                    fee_trade_fixed: trade?.fixed,
                    fee_trade_limit_percent: trade?.limit_percent,
                    fee_trade_market_percent: trade?.market_percent,
                    fee_trade_min: trade?.min_commission,
                });

                commissionsTrade.push({
                    pair_id,
                    pair_name: iso3 + 'USDT',
                    main: {
                        id,
                        iso3
                    },
                    fixed: trade?.fixed,
                    limit_percent: trade?.limit_percent,
                    market_percent: trade?.market_percent,
                    min: trade?.min_commission,
                });
            }

            state.commissions = commissions;
            state.commissionsTrade = commissionsTrade;
        },
        SET_CURRENCIES_ICONS(state, data) {
            state.currencies_icons = data;
        },
        SET_SETTINGS_UPDATES_HISTORY_LIST(state, data) {
            state.settingsUpdates = data;
        },

        SET_MODAL_IS_FULLSCREEN(state, data) {
            state.modalIsFullscreen = data;
        },
        SET_LANGUAGES_LIST(state, data) {
            state.languages = data;
        },
        SET_TOP_TICKERS_LIST(state, data) {
            state.topTickers = data;
        },

        SET_FRONTEND_DOMAIN(state, data) {
            state.frontend_domain = data;
        },
    },
    actions: {
        loadConfigs(context) {
            // Do get data
            return axios.get('/api/default/config')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        // Set commission deposit and withdraw
                        context.commit('SET_CURRENCIES_COMMISSIONS', data.data);

                        context.commit('SET_CURRENCIES_LIST', data.data.all_currency);

                        context.commit('SET_CURRENCY_DATA', data.data.currency_data);

                        // Add currencies icons
                        context.commit('SET_CURRENCIES_ICONS', data.data.currency_icons);

                        // Add pairs list
                        context.commit('SET_PAIRS_LIST', data.data.all_pairs);

                        // Site domain
                        context.commit('SET_FRONTEND_DOMAIN', data.data.frontend_domain);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadSettingsUpdatesHistory(context, params = {}) {
            // Do get data
            axios.get('/admin/api/user/settings-updates-history', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_SETTINGS_UPDATES_HISTORY_LIST', data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        setModalIsFullscreen(context, value) {
            context.commit('SET_MODAL_IS_FULLSCREEN', value);
        },
        /**
         *
         * @returns {*}
         */
        loadActiveLanguages(context) {
            return axios.get('/admin/api/default/langs')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_LANGUAGES_LIST', data.data.list);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadTopTickersList(context) {
            return axios.get('/admin/api/default/top-tickers')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TOP_TICKERS_LIST', data.data.ids);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateTopTickersList(context, params = {}) {
            return axios.post('/admin/api/default/top-tickers', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
