import sha256 from "crypto-js/sha256";

const KS = 'secret'
const getSecret = () => localStorage.getItem(KS);

function makeString(obj) {
    const sortedKeys = Object.keys(obj).sort((a, b) =>
        isNaN(a) || isNaN(b) ? String(a).localeCompare(b) : parseFloat(a) - parseFloat(b));

    //console.log(sortedKeys);

    let result = '';

    sortedKeys.forEach(key => {
        const value = obj[key];

        if (Array.isArray(value)) {
            value.forEach(item => {
                if (typeof item === 'object' && item !== null) {
                    result += makeString(item);
                } else {
                    result += (item !== undefined && item !== null) ? item.toString() : '';
                }
            });
        } else if (typeof value === 'object' && value !== null) {
            result += makeString(value);
        } else {
            result += (value !== undefined && value !== null) ? value.toString() : '';
        }
    });

    return result;
}

const encrypt = (sorted) => {
    let ready_string = makeString(sorted, '');

    //console.log(ready_string, sorted);

    const str_end = getSecret();

    if (str_end) {
        ready_string += str_end;
    }

    return sha256(ready_string.toString()).toString();
};

export default encrypt;
