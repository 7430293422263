import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

/**
 * Find first accessible route
 *
 * @param routes
 * @param userPermissions
 * @returns {{meta}|*|null}
 */
function findFirstAccessibleRoute(routes, userPermissions) {
    for (const route of routes) {
        // Check access for current routes
        if (hasPermission(route, userPermissions)) {
            return route;
        }

        // Recursive find in children
        if (route.children) {
            const childAccessibleRoute = findFirstAccessibleRoute(route.children, userPermissions);
            if (childAccessibleRoute) {
                return childAccessibleRoute;
            }
        }
    }

    // Not found
    return null;
}

/**
 * Check if has route permission
 *
 * @param route
 * @param userPermissions
 * @returns {boolean}
 */
function hasPermission (route, userPermissions) {
    // Has permissions
    let hasPermission = false;

    // We check whether the user has the necessary access rights for the current route
    const requiresPermissions = route?.meta?.requiresPermissions;

    if (requiresPermissions) {
        const permissions = route.meta.requiresPermissions.split('|').map(role => role.trim());

        if (permissions.length > 1) {
            hasPermission = permissions.some(role => userPermissions.enabled_permissions.includes(role));
        } else {
            const andPermissions = permissions[0].split('&').map(role => role.trim());
            if (andPermissions.length > 1) {
                hasPermission = andPermissions.every(role => userPermissions.enabled_permissions.includes(role));
            } else {
                hasPermission = userPermissions.enabled_permissions.includes(permissions[0]);
            }
        }
    }

    return hasPermission;
}

/**
 * Routes list configs
 */
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            children: [
                {
                    path: 'auth',
                    component: () => import('../views/default/DefaultLayout'),
                    children: [
                        {
                            path: 'login',
                            name: 'LoginPage',
                            component: () => import('../views/default/pages/LoginPage.vue'),
                        },
                    ]
                }
            ]
        },
        {
            path: '/',
            component: () => import('../views/dashboard/DefaultLayout'),
            children: [
                {
                    path: '/',
                    name: 'UsersListPage',
                    component: () => import('../views/dashboard/pages/UsersPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_USER',
                    }
                },
                {
                    path: '/users/:userId/:tab_key',
                    name: 'UserDetailPage',
                    component: () => import('../views/dashboard/pages/UsersPage.vue'),
                    props: true,
                    meta: {
                        requiresPermissions: 'MANAGER_USER',
                    }
                },
                {
                    path: '/users/:userId/:tab_key/:documentId',
                    name: 'UserDocumentPage',
                    component: () => import('../views/dashboard/pages/UsersPage.vue'),
                    props: true,
                    meta: {
                        requiresPermissions: 'MANAGER_USER',
                    }
                },
                {
                    path: '/users/:userId/document/:documentId',
                    name: 'UserVerificationDocument',
                    component: () => import('../views/dashboard/pages/VerificationDocumentPage.vue'),
                    props: true,
                    meta: {
                        requiresPermissions: 'MANAGER_USER',
                    }
                },
                {
                    path: '/operations',
                    name: 'OperationsListPage',
                    component: () => import('../views/dashboard/pages/OperationsListPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_COIN_ORDER&MANAGER_FIAT_ORDERS',
                    }
                },
                {
                    path: '/operations/:userId',
                    name: 'OperationsListPageByUser',
                    component: () => import('../views/dashboard/pages/OperationsListPage.vue'),
                    props: true,
                    meta: {
                        requiresPermissions: 'MANAGER_COIN_ORDER&MANAGER_FIAT_ORDERS',
                    }
                },
                {
                    path: '/operations/:userId/:tab_key',
                    name: 'OperationsListPageByUserWithTab',
                    component: () => import('../views/dashboard/pages/OperationsListPage.vue'),
                    props: true,
                    meta: {
                        requiresPermissions: 'MANAGER_COIN_ORDER&MANAGER_FIAT_ORDERS',
                    }
                },
                {
                    path: '/users/:userId/:tab_key/:documentId',
                    name: 'OperationsListPageByUserWithTabDocument',
                    component: () => import('../views/dashboard/pages/OperationsListPage.vue'),
                    props: true,
                    meta: {
                        requiresPermissions: 'MANAGER_USER',
                    }
                },
                {
                    path: '/affiliates',
                    name: 'AffiliatesListPage',
                    component: () => import('../views/dashboard/pages/AffiliatesPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_AFFILIATE',
                    }
                },
                {
                    path: '/dashboard',
                    name: 'DashboardPage',
                    component: () => import('../views/dashboard/pages/DashboardPage.vue'),
                    meta: {
                        requiresPermissions: 'STATISTIC',
                    }
                },
                // {
                //     path: "/fees",
                //     name: "CurrenciesFeesListPage",
                //     component: () => import('../views/dashboard/pages/FeesPage.vue'),
                //     meta: {
                //         requiresPermissions: 'ADMIN',
                //     }
                // },
                {
                    path: "/coins-pairs",
                    name: "CurrenciesListPage",
                    component: () => import('../views/dashboard/pages/CoinsPairsPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_CURRENCY&MANAGER_CURRENCY_PAIR',
                    }
                },
                {
                    path: "/coins-pairs/:coin",
                    name: "CurrenciesListPageByCoin",
                    component: () => import('../views/dashboard/pages/CoinsPairsPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_CURRENCY&MANAGER_CURRENCY_PAIR',
                    }
                },
                {
                    path: "/users-assets",
                    name: "AllUsersAssetsPage",
                    component: () => import('../views/dashboard/pages/UsersAssetsPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_USER',
                    }
                },
                {
                    path: "/changes-log",
                    name: "ChangesLogPage",
                    component: () => import('../views/dashboard/pages/SettingsUpdatesHistoryPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_LOG',
                    }
                },
                {
                    path: "/contents",
                    name: "ContentsListPage",
                    component: () => import('../views/dashboard/pages/ContentsPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_NEWS',
                    }
                },
                {
                    path: "/system-wallets",
                    name: "SystemWalletsListPage",
                    component: () => import('../views/dashboard/pages/SystemWalletsListPage.vue'),
                    meta: {
                        requiresPermissions: 'WALLETS',
                    }
                },
                {
                    path: "/bots",
                    name: "BotsPage",
                    component: () => import('../views/dashboard/pages/BotsPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGER_BOT',
                    }
                },
                // {
                //     path: "/reward-ranges",
                //     name: "RewardRanges",
                //     component: () => import('../views/dashboard/pages/RewardRangesPage.vue'),
                //     meta: {
                //         requiresPermissions: 'ADMIN',
                //     }
                // },
                {
                    path: "/bonus-programs",
                    name: "ReferralPrograms",
                    component: () => import('../views/dashboard/pages/BonusProgramsPage.vue'),
                    meta: {
                        requiresPermissions: 'ADMIN',
                    }
                },
                {
                    path: "/leaderboard",
                    name: "LeaderboardPage",
                    component: () => import('../views/dashboard/pages/LeaderboardPage.vue'),
                    meta: {
                        requiresPermissions: 'LEADERBOARD_VIEW',
                    }
                },
                {
                    path: "/launchpad",
                    name: "LaunchpadPage",
                    component: () => import('../views/dashboard/pages/LaunchpadPage.vue'),
                    meta: {
                        requiresPermissions: 'MANAGE_IEO_PROJECT',
                    }
                },
                {
                    path: "/access-denied",
                    name: "AccessDenied",
                    component: () => import('../views/dashboard/pages/AccessDeniedPage.vue')
                },
            ]
        },
    ],
    scrollBehavior() {
        return { top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    // Get user permissions from api
    let userAccess = store.getters.getUserPermissions;

    // Get user permissions from api
    if (!userAccess && store.getters.isLoggedIn) {
        store.dispatch('loadUserPermissions').then((r) => {
            userAccess = r.data;

            checkPermission(to, userAccess);
        });
    }

    // Watch user role & permissions
    if (userAccess && to.path !== '/auth/login') {
        checkPermission(to, userAccess);
    }

    next();
});

export default router;

/**
 * @param route
 * @param userPermissions
 */
export function checkPermission (route, userPermissions){
    // If access is denied, we look for the first available route
    if (!hasPermission(route, userPermissions)) {
        // Find the first route the user has access to
        const firstAccessibleRoute = findFirstAccessibleRoute(router.options.routes, userPermissions);

        // Redirect to the first accessible route
        if (firstAccessibleRoute) {
            router.push(firstAccessibleRoute.path);
        }
    }
}

/**
 *
 */
export function redirectToLogin() {
    router.push({
        name: 'LoginPage',
        params: {
            redirect: window.location.href
        }
    });
}

/**
 *
 * @returns {boolean}
 */
export function isOnLoginPage() {
    return ['LoginPage'].includes(router.currentRoute.value.name);
}
