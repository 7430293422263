<script>
import {redirectToLogin} from "@/router";
import axios from './utils/api';

export default {
    name: 'App',
    data: () => ({
        menuLinks: [
            {
                href: "/dashboard",
                label: "Dashboard",
                name: 'DashboardPage',
                enabled: '1'
            },
            {
                href: "/",
                label: "Users",
                name: 'UsersListPage',
                enabled: '1'
            },
            {
                href: "/operations",
                label: "All Operations",
                name: 'OperationsListPage',
                enabled: '1'
            },
            {
                href: "/affiliates",
                label: "Affiliates",
                name: 'AffiliatesListPage',
                enabled: '1'
            },
            // {
            //     href: "/fees",
            //     label: "Fees",
            //     name: "CurrenciesFeesListPage",
            //     enabled: '1'
            // },
            {
                href: "/coins-pairs",
                label: "Coins & Pairs",
                name: "CurrenciesListPage",
                enabled: '1'
            },
            {
                href: "/changes-log",
                label: "Changes Log",
                name: "ChangesLogPage",
                enabled: '1'
            },
            {
                href: "/contents",
                label: "Contents",
                name: "ContentsListPage",
                enabled: '1'
            },
            {
                href: "/system-wallets",
                label: "System Wallets",
                name: "SystemWalletsListPage",
                enabled: '1'
            },
            {
                href: "/users-assets",
                label: "All Users Assets",
                name: "AllUsersAssetsPage",
                enabled: '1'
            },
            {
                href: "/bots",
                label: "Bots",
                name: "BotsPage",
                enabled: '1'
            },
            {
                href: "/bonus-programs",
                label: "Bonus Programs",
                name: "ReferralPrograms",
                enabled: '1'
            },
            {
                href: "/leaderboard",
                label: "Leaderboard",
                name: "LeaderboardPage",
                enabled: '1'
            },
            {
                href: "/launchpad",
                label: "Launchpad",
                name: "LaunchpadPage",
                enabled: '1'
            },
            // {
            //     href: "/reward-ranges",
            //     label: "Reward Ranges",
            //     name: "RewardRanges",
            //     enabled: '1'
            // },
        ]
    }),
    computed: {
        isUserLogged() {
            return this.$store.getters.isLoggedIn;
        },
        userAccess() {
            return this.$store.getters.getUserPermissions;
        },
        filteredMenuLinks() {
            if (!this.userAccess || !this.userAccess.enabled_permissions){
                return [];
            }

            const filteredLinks = [];

            for (const link of this.menuLinks) {
                const route = this.$router.getRoutes().find(route => route.name === link.name);

                if (!route || !route.meta || !route.meta.requiresPermissions) {
                    continue;
                }

                const permissions = route.meta.requiresPermissions.split('|').map(role => role.trim());

                let hasPermission = false;

                if (permissions.length > 1) {
                    hasPermission = permissions.some(role => this.userAccess.enabled_permissions.includes(role));
                } else {
                    const andPermissions = permissions[0].split('&').map(role => role.trim());
                    if (andPermissions.length > 1) {
                        hasPermission = andPermissions.every(role => this.userAccess.enabled_permissions.includes(role));
                    } else {
                        hasPermission = this.userAccess.enabled_permissions.includes(permissions[0]);
                    }
                }

                if (hasPermission) {
                    filteredLinks.push(link);
                }
            }

            return filteredLinks;
        }
    },
    created() {
        this.$store.dispatch('checkAuth');

        if (!this.isUserLogged) {
            redirectToLogin();
        }
    },
    methods: {
        async handleLogout() {
            // Do logout
            const response = await axios.get('/admin/api/auth/logout', {
                headers: {
                    'login-token': localStorage.getItem('token'),
                    'X-Auth-Sign': localStorage.getItem('secret'),
                }
            });

            // Clear local storage
            const lang = localStorage.getItem('lang');
            localStorage.clear();
            localStorage.setItem('lang', lang);

            this.$store.commit('SET_LOGGED_STATUS', false);
            this.$store.commit('SET_USER_ROLE', null);

            this.$router.push('/auth/login');

            // Return response
            return response.data;
        }
    }
}
</script>

<template>
    <div class="container mb-5">
        <nav class="navbar sticky-top navbar-expand-lg bg-light mb-5">
            <div class="container-fluid align-items-center">
                <router-link class="navbar-brand pt-0" :to="{name: 'UsersListPage'}">{{ $VUE_APP_TITLE }}</router-link>
                <div v-if="isUserLogged" class="d-flex justify-content-between align-items-center w-100">
                    <ul
                        class="navbar-nav me-auto flex-row flex-wrap"
                    >
                        <template v-for="(link, i) in filteredMenuLinks" :key="i">
                            <li v-if="link.enabled === '1'" class="nav-item px-1 pb-2">
                                <router-link
                                    class="btn btn-sm"
                                    :class="{ 'btn-secondary': $route.href === link.href, 'btn-primary': $route.href !== link.href}"
                                    aria-current="page"
                                    :to="{name: link.name}"
                                >{{ link.label }}</router-link>
                            </li>
                        </template>
                    </ul>
                    <button class="btn btn-secondary" type="button" @click="handleLogout">Log out</button>
                </div>
            </div>
        </nav>
        <RouterView />
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/styles.scss";
</style>
